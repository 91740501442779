#admin-bar {
    display: block;
    width: 100%;
    height: 0;
    transition: height 100ms ease;

    .admin-bar {
        display: block;
        position: fixed;
        width: 100%;
        background-color: $accent-primary;
        z-index: 1000;

        nav {
            ul {
                display: flex;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    a {
                        color: $light;
                        padding: 0 5px;
                        margin: 0 3px;
                        text-decoration: none;

                        &:after {
                            background-color: $light;
                        }
                    }
                }
            }
        }
    }
}
