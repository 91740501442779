$bp-xs: 576px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;

$accent-primary: #f06414;
$accent-secondary: #F79D19;
$dark: #000000;
$light: #ffffff;
$success: #00ff00;
$warning: #ffaa00;
$error: #f00000;

$font: 'Varela Round', sans-serif;
