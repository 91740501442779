body {
  background-color: #fff;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, .75);
}
::-webkit-scrollbar-thumb {
  background: #000;
}
::-webkit-scrollbar-button {
  display: none;
}

::selection {
    background-color: $accent-secondary;
}

main {
  min-height: 60vh;
  background-color: #fff;
}

p {
  margin: 0;
}

a:not(.btn) {
  color: $accent-primary;
  text-decoration: none;
  position: relative;
  transition: color 150ms ease;

  &:after {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $accent-primary;
    transition: width 200ms ease;
    opacity: .75;
  }

  &:hover {
    text-decoration: none;
    color: $accent-secondary;

    &:after {
      width: 0;
      left: unset;
      right: 0;
    }
  }
}

// page title
.page-title-wrapper {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark;
  color: $light;
  position: relative;
  overflow: hidden;

  .page-title {
    //position: fixed;

    h1 {
      margin: 0;
      font-family: $font;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &:after {
    content: '';
    background-color: $light;
    position: absolute;
    width: 100%;
    height: 100px;
    bottom: -86px;
    transform: rotateZ(-1deg);
    box-shadow: 0px -2px 2px 0px #fff;
  }
}
