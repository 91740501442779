footer#footer {
  background-color: $dark;
  color: #fff;
  width: 100vw;
  box-shadow: 0 0 5px 0 #000;
  overflow: hidden;

  .footer-wrapper {
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
  }

  #bottom-bar {
    background-color: $light;
    color: #000;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .5s ease;

    p {
      font-size: 13px;
    }
  }
}
