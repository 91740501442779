header {
  display: flex;

  #menu-btn-element {
    z-index: 4;
    cursor: pointer;
    user-select: none;
    position: fixed;

    @media screen and (min-width: $bp-xs) {
      background-color: $light;
      box-shadow: 1px 1px 5px #000;
      height: 500px;
      margin-left: 0;
      margin-top: -310px;
      transform: rotate(70deg);
      width: 300px;
      transition: background-color .5s ease-in-out;
    }

    @media screen and (max-width: $bp-xs - 1) {
      width: 170px;
    }

    div#menu-btn {
      @media screen and (min-width: $bp-xs) {
        transform: rotate(-70deg);
        position: absolute;
        top: 305px;
        left: 165px;
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: $bp-xs - 1) {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(-45deg, transparent 15px, $dark 15px);
      }

      .menu-icon, .menu-icon:before, .menu-icon:after {
        width: 25px;
        height: 3px;
        background-color: $dark;
        position: absolute;

        @media screen and (min-width: $bp-xs) {
          transition: 1s background-color;
        }

        @media screen and (max-width: $bp-xs - 1) {
          background-color: $light;
        }
      }

      .menu-icon {
        @media screen and (max-width: $bp-xs - 1) {
          position: relative;
          top: -2px;
        }

        &:before {
          content: "";
          top: -10px;
        }

        &:after {
          content: "";
          bottom: -10px;
        }
      }

      p.menu-text {
        @media screen and (min-width: $bp-xs) {
          font-family: $font;
          font-size: 50px;
          color: #000;
          margin-bottom: 6px;
          margin-left: 30px;
          transition: 1s color;
        }

        @media screen and (max-width: $bp-xs - 1) {
          color: #fff;
          font-size: 34px;
          margin-left: 10px;
        }
      }
    }

    &:hover {
      @media screen and (min-width: $bp-xs) {
        background-color: $dark;
      }

      div#menu-btn {
        .menu-icon, .menu-icon:before, .menu-icon:after {
          background-color: $light;
        }

        p.menu-text {
          color: #fff;
        }
      }
    }
  }

  nav#main-menu {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    background-color: $dark;
    height: 100vh;
    left: 0;
    max-height: 100vh;
    width: 100vw;
    max-width: 170px;
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 160px;
    position: fixed;
    transition: 400ms ease-in-out;
    z-index: 3;
    direction: rtl;
    box-shadow: 1px 1px 5px #000;

    @media screen and (max-width: $bp-xs - 1) {
      padding-top: 60px;
    }

    &.menu-closed {
      left: -210px;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    li.menu-item {
      display: block;
      margin: 5%;

      &:first-of-type {
        margin-top: 0;
      }

      a {
        align-items: center;
        background-color: $light;
        border-radius: 8px;
        box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.8);
        color: #000;
        display: flex;
        font-family: $font;
        font-size: 24px;
        font-weight: bold;
        height: 80px;
        justify-content: center;
        transition: 200ms;
        text-decoration: none;

        &:after {
          display: none;
        }
      }

      &:hover {
        a {
          background-color: $accent-primary;
          color: #fff;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: none;
    }
    &::-webkit-scrollbar-thumb {
      background: $light;
    }
  }
}
